import {defineStore} from 'pinia'
import {ref, computed, watch} from 'vue'

export const useQuotationStore = defineStore('quotation', () => {
    const products = ref([])
    const isInit = ref(false)
    const isActive = ref(false)
    const form = ref({
        type: 'private',
        name: '',
        address: '',
        zip: '',
        phone: '',
        email: '',
        country: 'SE',
        comment: '',
        companyNumber: '',
        companyName: '',
    })

    const quotationIsActive = computed(() => isActive.value)

    function clearProducts() {
        products.value = []
    }

    function addProduct(image, name, sku, unit, unit_per_box, url) {
        if (products.value.some(product => product.sku === sku)) {
            return
        }

        products.value.push({image, name, sku, unit, unit_per_box, quantity: 1, url})
    }

    function removeProduct(sku) {
        products.value = products.value.filter(product => product.sku !== sku)
    }

    function closeQuotation() {
        setTimeout(() => {
            isActive.value = false
        }, 500)
    }

    function openQuotation() {
        isInit.value = true

        setTimeout(() => {
            isActive.value = true
        }, 500)
    }

    function toggleQuotation() {
        isInit.value = true

        setTimeout(() => {
            isActive.value = !isActive.value
        }, 500)
    }

    function updateTabs() {
        const worker = new SharedWorker('quotation.js')
        worker.port.postMessage({key: 'myData', value: 'Hello from Tab 1!'})
    }

    // Watcher for cartIsEmpty
    watch(() => form.value.email, (newEmail, oldEmail) => {
        useCookie('quotation-email').value = newEmail
    })

    onMounted(() => {
        if (!form.value.email) {
            form.value.email = useCookie('quotation-email').value
        }
    })

    return {
        products,
        isInit,
        isActive,
        form,
        quotationIsActive,
        clearProducts,
        addProduct,
        removeProduct,
        closeQuotation,
        openQuotation,
        toggleQuotation,
        updateTabs
    }
}, {
    persist: {
        key: 'quotationStore',
        paths: ['products', 'form'],
        storage: persistedState.localStorage,
    }
})
